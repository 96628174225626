import React, { useState } from "react";
import "./salary_calculator_styles.css";

function SalaryCalculator() {
  // State variables to handle the selected career path
  const [careerPath, setCareerPath] = useState("Commercial / Functional");

  // State variables to handle the percentage inputs, experience, and results
  const [percentages, setPercentages] = useState({
    learner: 0,
    specialist: 0,
    pilot: 0,
    changemaker: 0,
    strategist: 0,
    visionary: 0,
    professor: 0,
    luminary: 0,
  });

  const [experienceSteps, setExperienceSteps] = useState(0);
  const [result, setResult] = useState({
    profile: "No profile selected",
    basis: 0,
    bonus: 0,
    experienceContribution: 0,
    newSalary: 0,
  });
  const [detailedCalculation, setDetailedCalculation] = useState("");

  // Profiles data for basis salaries and calculations
  const profilesCommercial = [
    { name: "The Learner", basis: 2500, key: "learner" },
    { name: "The Specialist", basis: 3100, key: "specialist" },
    { name: "The Pilot", basis: 3800, key: "pilot" },
    { name: "The Changemaker", basis: 4600, key: "changemaker" },
    { name: "The Strategist", basis: 5500, key: "strategist" },
    { name: "The Visionary", basis: 7500, key: "visionary" },
  ];

  const profilesTechnical = [
    { name: "The Learner", basis: 2500, key: "learner" },
    { name: "The Specialist", basis: 3100, key: "specialist" },
    { name: "The Pilot", basis: 3800, key: "pilot" },
    { name: "The Professor", basis: 4600, key: "professor" },
    { name: "The Luminary", basis: 6200, key: "luminary" },
  ];

  // Function to handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPercentages((prev) => ({ ...prev, [name]: parseFloat(value) }));
  };

  const handleExperienceChange = (e) => {
    setExperienceSteps(parseFloat(e.target.value));
  };

  // Function to reset all values to 0
  const resetValues = () => {
    setPercentages({
      learner: 0,
      specialist: 0,
      pilot: 0,
      changemaker: 0,
      strategist: 0,
      visionary: 0,
      professor: 0,
      luminary: 0,
    });
    setExperienceSteps(0);
    setResult({ profile: "No profile selected", basis: 0, bonus: 0, newSalary: 0 });
    setDetailedCalculation("");
  };

  // Function to handle career path change and reset forms
  const handleCareerPathChange = (e) => {
    setCareerPath(e.target.value);
    resetValues();
  };

  // Salary Calculation Function
  const calculateSalary = () => {
    let selectedProfileIndex = -1;
    const profiles =
      careerPath === "Commercial / Functional"
        ? profilesCommercial
        : profilesTechnical;

    // Find the highest profile with at least 75%
    for (let i = profiles.length - 1; i >= 0; i--) {
      if (percentages[profiles[i].key] >= 75) {
        selectedProfileIndex = i;
        break;
      }
    }

    if (selectedProfileIndex !== -1) {
      const selectedProfile = profiles[selectedProfileIndex];
      const basisSalary = selectedProfile.basis;
      let growthBonus = 0;
      let calculationLog = `Calculating for Profile: ${selectedProfile.name}\n`;
      calculationLog += `Basis Salary: €${basisSalary}\n`;

      if (
        selectedProfileIndex < profiles.length - 1 &&
        percentages[selectedProfile.key] > 0
      ) {
        const nextProfile = profiles[selectedProfileIndex + 1];
        const difference = nextProfile.basis - basisSalary;
        const nextProfilePercentage = percentages[nextProfile.key] / 100;

        calculationLog += `Next Profile Basis Salary: €${nextProfile.basis}\n`;
        calculationLog += `Difference Between Profiles: €${difference}\n`;
        calculationLog += `Next Profile Percentage: ${
          percentages[nextProfile.key]
        }%\n`;

        const calculatedBonus = (difference * nextProfilePercentage) / 0.75;
        calculationLog += `Growth Bonus Calculation: (${difference} * ${nextProfilePercentage}) / 0.75 = €${calculatedBonus.toFixed(
          2
        )}\n`;

        growthBonus = Number(calculatedBonus.toFixed(2));
        calculationLog += `Growth Bonus (after rounding): €${growthBonus}\n`;
      }

      let newSalary = 0;
      let experienceContribution = 0;
      if (percentages[selectedProfile.key] >= 75) {
        experienceContribution =
          (basisSalary + growthBonus) * (experienceSteps * 0.01);
        newSalary = basisSalary + growthBonus + experienceContribution;
        newSalary = Number(newSalary.toFixed(2));
        calculationLog += `Experience Steps Contribution: ((€${basisSalary} + €${growthBonus}) * ${experienceSteps}%) = €${experienceContribution.toFixed(
          2
        )}\n`;
      }

      calculationLog += `New Salary: €${newSalary.toFixed(2)}\n`;

      setResult({
        profile: selectedProfile.name,
        basis: basisSalary.toFixed(2),
        bonus: growthBonus.toFixed(2),
        newSalary: newSalary.toFixed(2),
        experienceContribution: experienceContribution.toFixed(2),
      });
      setDetailedCalculation(calculationLog);
    } else {
      setResult({
        profile: "No profile selected",
        basis: 0,
        bonus: 0,
        newSalary: 0,
        experienceContribution: 0,
      });
      setDetailedCalculation("");
    }
  };

  return (
    <div>
      <header>
        <h2>Salary Calculator 2024</h2>
        <div className="input-container">
          <label>
            Career path:{" "}
            <select
              className="custom-select"
              value={careerPath}
              onChange={handleCareerPathChange}
            >
              <option>Commercial / Functional</option>
              <option>Technical</option>
            </select>
          </label>
        </div>
        <div className="salary-container">
          <div className="form-container">
            {(careerPath === "Commercial / Functional"
              ? profilesCommercial
              : profilesTechnical
            ).map((profile) => (
              <div key={profile.key} className="input-container">
                <label>{profile.name}: </label>
                <input
                  type="number"
                  name={profile.key}
                  value={percentages[profile.key]}
                  onChange={handleInputChange}
                  min="0"
                  max="100"
                  className="percentage-input"
                />
                <span className="percentage-symbol">%</span>
              </div>
            ))}
            <div className="input-container">
              <label>Experience Steps: </label>
              <input
                type="number"
                value={experienceSteps}
                onChange={handleExperienceChange}
                min="0"
                max="4"
                className="experience-input"
              />
            </div>
          </div>
          <div className="results-container">
            <div className="">
              <div className="detailed">Results:</div>
              <div className="results-div">Profile: {result.profile}</div>
              <div className="results-div">Basis Salary: €{result.basis}</div>
              <div className="results-div">Growth Bonus: €{result.bonus}</div>
              <div className="results-div">
                Experience Steps Contribution: €{result.experienceContribution}
              </div>
              <div className="results-div">New Salary: €{result.newSalary}</div>
              <br />
              <div className="detailed">Detailed Calculation:</div>
              <pre>{detailedCalculation}</pre>
            </div>
          </div>
        </div>
        <div className="buttons">
          <button onClick={calculateSalary}>Calculate</button>
          <button onClick={resetValues} style={{ marginTop: "10px" }}>
            Reset
          </button>
        </div>
      </header>
    </div>
  );
}

export default SalaryCalculator;
