import React, { useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode"; // Import jwtDecode directly
import { fetchUsers } from "./api"; // Import the fetchUsers function
import Swal from "sweetalert2";
import "./salary_calculator_styles.css";

const Login = ({ onLogin }) => {
  const [loading, setLoading] = useState(false);

  const handleLogin = async (credentialResponse) => {
    setLoading(true); // Start loading
    try {
      if (!credentialResponse || !credentialResponse.credential) {
        throw new Error("Credential is either missing or not returned.");
      }

      const idToken = credentialResponse.credential; // Extract the ID token
      const userInfo = jwtDecode(idToken); // Decode the ID token

      // Fetch users from the database and check if user exists
      const users = await fetchUsers();
      const userInDatabase = users.find(
        (user) => user.email === userInfo.email
      );

      if (userInDatabase) {
        // Create a user object with additional data
        const userWithRoleAndGoogleId = {
          ...userInfo,
          role: userInDatabase.role,
          google_id: userInDatabase.google_id, // Include google_id here
        };

        // Pass the full user object to parent component
        onLogin(userWithRoleAndGoogleId);
      } else {
        Swal.fire({
          title: "Sorry!",
          text: "Unfortunately, we were unable to identify you as an Ecare employee, so you do not have access to this tool.",
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error during login:", error);
      Swal.fire({
        title: "Error",
        text: "Oops.. something went wrong!",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div style={{ textAlign: "center" }}>
      {loading ? (
        <div className="spinner"></div> // Display spinner when loading
      ) : (
        <GoogleLogin
          onSuccess={handleLogin}
          onError={(error) => console.log("Login failed:", error)}
          cookiePolicy={"single_host_origin"}
          size="large"
          shape="rectangular"
          width="200px"
          ux_mode="popup"
        />
      )}
    </div>
  );
};

export default Login;
