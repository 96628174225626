import React, { useEffect, useState, useCallback } from "react";
import SalaryCalculator from "./SalaryCalculator";
import Login from "./Login";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Swal from "sweetalert2";
import "./salary_calculator_styles.css";

const INACTIVITY_LIMIT = 600000; // 10 minutes

const App = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [redirectToApplications, setRedirectToApplications] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const handleLogin = (user) => {
    setUserInfo(user);
    setRedirectToApplications(true);
    startInactivityTimer(); // Start inactivity timer on login
  };

  const handleLogout = () => {
    setUserInfo(null);
    clearTimeout(timeoutId); // Clear timeout on logout
  };

  const startInactivityTimer = useCallback(() => {
    clearTimeout(timeoutId); // Clear any existing timeout

    const id = setTimeout(() => {
      handleLogout();
      Swal.fire({
        title: "Logged Out",
        text: "You have been logged out due to inactivity.",
        icon: "warning",
        customClass: "swal-wide",
        confirmButtonText: "OK",
      });
    }, INACTIVITY_LIMIT);

    setTimeoutId(id); // Save the new timeout ID
  }, [timeoutId]);

  const resetInactivityTimer = () => {
    startInactivityTimer(); // Restart the inactivity timer on any user interaction
  };

  useEffect(() => {
    if (redirectToApplications) {
      setRedirectToApplications(false);
    }
  }, [redirectToApplications]);

  useEffect(() => {
    // Set up event listeners for user activity
    window.addEventListener("mousemove", resetInactivityTimer);
    window.addEventListener("keypress", resetInactivityTimer);
    window.addEventListener("click", resetInactivityTimer);

    // Cleanup on component unmount
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("mousemove", resetInactivityTimer);
      window.removeEventListener("keypress", resetInactivityTimer);
      window.removeEventListener("click", resetInactivityTimer);
    };
  }, [timeoutId, resetInactivityTimer]);

return (
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <Router>
      <div className="App">
        <header className="App-header">
          {userInfo ? (
            <Routes>
              <Route path="/" element={<SalaryCalculator />} />
            </Routes>
          ) : (
            <>
              <h2>Salary Calculator 2024</h2>
              <p className="detailed">Login with your Visma account</p>
              <Login onLogin={handleLogin} />
            </>
          )}
        </header>
      </div>
    </Router>
  </GoogleOAuthProvider>
);
};

export default App;

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <SalaryCalculator />
//       </header>
//     </div>
//   );
// }

// export default App;
